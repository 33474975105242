<template>
  <template v-if="isZhCn">
    <div><strong>{{ usefulInfoItem.name_cn }}</strong></div>
    <template v-for="mitem in usefulInfoItem.items" v-bind:key="mitem.id">
      <span class="hotel-policy-item" v-if="mitem.value"><CheckOutlined /> {{mitem.title_cn}}: <span class="hotel-policy-item-value">{{mitem.value}}</span></span>
    </template>
  </template>
  <template v-else>
    <div><strong>{{ usefulInfoItem.name_en }}</strong></div>
    <template v-for="mitem in usefulInfoItem.items" v-bind:key="mitem.id">
      <span class="hotel-policy-item" v-if="mitem.value"><CheckOutlined /> {{mitem.title_en}}: <span class="hotel-policy-item-value">{{mitem.value}}</span></span>
    </template>
  </template>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";

export default {
  name: "UsefulInfoItem",
  components: {
    CheckOutlined
  },
  props: {
    usefulInfoItem: Object,
    isZhCn: Boolean
  },
}
</script>

<style scoped>
.hotel-policy-item {
  margin-right: 20px;
}
.hotel-policy-item-value {
  font-weight: 500;
}
</style>