export const SpecialRequestItems = [
    {value:'Non smoking room', label:'禁烟房'},
    {value:'Smoking room', label:'吸烟房'},
    {value:'Double bed', label:'大床房'},
    {value:'Twin bed', label:'双床房'},
    {value:'Separate beds', label:'单独的床'},
    {value:'Baby cot', label:'婴儿床'},
    {value:'Internet access', label:'可联网'},
    {value:'Bathtub', label:'浴缸'},
    {value:'Aircondition', label:'空调'},
    {value:'Arrive immediately', label:'立即到店'},
    {value:'Early checkout', label:'提前退房'},
    {value:'Connecting room', label:'内部相通房'},
    {value:'Adjacent rooms', label:'相邻房'},
    {value:'Quiet room', label:'安静房间'},
    {value:'Honeymoon room', label:'蜜月房'},
    {value:'Disabled facilities room', label:'残疾设施房间'},
    {value:'Higher floor room', label:'高层房间'},
    {value:'Lower floor room', label:'低层楼'},
    {value:'Ground floor room', label:'一楼房间'},
    {value:'Same floor rooms', label:'相同楼层房间'},
    {value:'Require extended stay in original room', label:'原房续住'},
    {value:'Late checkout', label:'晚点退房'}
]