//常用国籍
export const CommonNationalityCodes = ['CN','ES','GB','HK','JP','KR','MO','TH','TW','US']
//所有国家
export const AllCountries = [
    {code:'AD',name_en:'Andorra',name_cn:'安道尔'},
    {code:'AE',name_en:'United Arab Emirates',name_cn:'阿拉伯联合酋长国'},
    {code:'AF',name_en:'Afghanistan',name_cn:'阿富汗'},
    {code:'AG',name_en:'Antigua and Barbuda',name_cn:'安提瓜和巴布达'},
    {code:'AI',name_en:'Anguilla',name_cn:'安圭拉岛'},
    {code:'AL',name_en:'Albania',name_cn:'阿尔巴尼亚'},
    {code:'AM',name_en:'Armenia',name_cn:'亚美尼亚'},
    {code:'AO',name_en:'Angola',name_cn:'安哥拉'},
    {code:'AR',name_en:'Argentina',name_cn:'阿根廷'},
    {code:'AS',name_en:'American Samoa',name_cn:'美属萨摩亚'},
    {code:'AT',name_en:'Austria',name_cn:'奥地利'},
    {code:'AU',name_en:'Australia',name_cn:'澳大利亚'},
    {code:'AW',name_en:'Aruba',name_cn:'阿鲁巴岛'},
    {code:'AX',name_en:'Aland Islands',name_cn:'阿兰群岛'},
    {code:'AZ',name_en:'Azerbaijan',name_cn:'阿塞拜疆'},
    {code:'BA',name_en:'Bosnia and Herzegovina',name_cn:'波斯尼亚'},
    {code:'BB',name_en:'Barbados',name_cn:'巴巴多斯'},
    {code:'BD',name_en:'Bangladesh',name_cn:'孟加拉国'},
    {code:'BE',name_en:'Belgium',name_cn:'比利时'},
    {code:'BF',name_en:'Burkina Faso',name_cn:'布吉纳法索'},
    {code:'BG',name_en:'Bulgaria',name_cn:'保加利亚'},
    {code:'BH',name_en:'Bahrain',name_cn:'巴林'},
    {code:'BI',name_en:'Burundi',name_cn:'蒲隆地'},
    {code:'BJ',name_en:'Benin',name_cn:'贝宁'},
    {code:'BL',name_en:'Saint Barthelemy',name_cn:'圣巴泰勒米岛'},
    {code:'BM',name_en:'Bermuda',name_cn:'百慕大'},
    {code:'BN',name_en:'Brunei Darussalam',name_cn:'文莱'},
    {code:'BO',name_en:'Bolivia',name_cn:'玻利维亚'},
    {code:'BQ',name_en:'Bonaire, Sint Eustatius and Saba',name_cn:'圣尤斯特歇斯'},
    {code:'BR',name_en:'Brazil',name_cn:'巴西'},
    {code:'BS',name_en:'The Bahamas',name_cn:'巴哈马'},
    {code:'BT',name_en:'Bhutan',name_cn:'不丹'},
    {code:'BW',name_en:'Botswana',name_cn:'博茨瓦纳'},
    {code:'BY',name_en:'Belarus',name_cn:'白俄罗斯'},
    {code:'BZ',name_en:'Belize',name_cn:'伯利兹'},
    {code:'CA',name_en:'Canada',name_cn:'加拿大'},
    {code:'CC',name_en:'Cocos (Keeling) Islands',name_cn:'科科斯（基林）群岛'},
    {code:'CD',name_en:'Congo Kinshasa',name_cn:'刚果(金)'},
    {code:'CF',name_en:'Central African Republic',name_cn:'中非共和国'},
    {code:'CG',name_en:'Congo Brazzaville',name_cn:'刚果(布)'},
    {code:'CH',name_en:'Switzerland',name_cn:'瑞士'},
    {code:'CI',name_en:'Cote D\'ivoire',name_cn:'科特迪瓦'},
    {code:'CK',name_en:'Cook Islands',name_cn:'库克群岛'},
    {code:'CL',name_en:'Chile',name_cn:'智利'},
    {code:'CM',name_en:'Cameroon',name_cn:'喀麦隆'},
    {code:'CN',name_en:'China',name_cn:'中国'},
    {code:'CO',name_en:'Colombia',name_cn:'哥伦比亚'},
    {code:'CR',name_en:'Costa Rica',name_cn:'哥斯达黎加'},
    {code:'CU',name_en:'Cuba',name_cn:'古巴'},
    {code:'CV',name_en:'Cape Verde',name_cn:'佛得角'},
    {code:'CW',name_en:'Curaao',name_cn:'库拉索'},
    {code:'CY',name_en:'Cyprus',name_cn:'塞浦路斯'},
    {code:'CZ',name_en:'Czech Republic',name_cn:'捷克'},
    {code:'DE',name_en:'Germany',name_cn:'德国'},
    {code:'DJ',name_en:'Djibouti',name_cn:'吉布提'},
    {code:'DK',name_en:'Denmark',name_cn:'丹麦'},
    {code:'DM',name_en:'Dominica',name_cn:'多米尼克'},
    {code:'DO',name_en:'Dominican Republic',name_cn:'多米尼加共和国'},
    {code:'DZ',name_en:'Algeria',name_cn:'阿尔及利亚'},
    {code:'EC',name_en:'Ecuador',name_cn:'厄瓜多尔'},
    {code:'EE',name_en:'Estonia',name_cn:'爱沙尼亚'},
    {code:'EG',name_en:'Egypt',name_cn:'埃及'},
    {code:'EH',name_en:'Western Sahara',name_cn:'西撒哈拉'},
    {code:'ER',name_en:'Eritrea',name_cn:'厄立特里亚'},
    {code:'ES',name_en:'Spain',name_cn:'西班牙'},
    {code:'ET',name_en:'Ethiopia',name_cn:'埃塞俄比亚'},
    {code:'FI',name_en:'Finland',name_cn:'芬兰'},
    {code:'FJ',name_en:'Fiji',name_cn:'斐济'},
    {code:'FK',name_en:'Falkland Islands (Malvinas)',name_cn:'福克兰群岛'},
    {code:'FM',name_en:'Micronesia, Federated States of',name_cn:'密克罗尼西亚联邦'},
    {code:'FO',name_en:'Faroe Islands',name_cn:'法罗群岛'},
    {code:'FR',name_en:'France',name_cn:'法国'},
    {code:'GA',name_en:'Gabon',name_cn:'加蓬'},
    {code:'GB',name_en:'United Kingdom',name_cn:'英国'},
    {code:'GD',name_en:'Grenada',name_cn:'格林纳达'},
    {code:'GE',name_en:'Georgia',name_cn:'格鲁吉亚'},
    {code:'GF',name_en:'French Guiana',name_cn:'法属圭亚那'},
    {code:'GG',name_en:'Guernsey',name_cn:'根西岛'},
    {code:'GH',name_en:'Ghana',name_cn:'加纳'},
    {code:'GI',name_en:'Gibraltar',name_cn:'直布罗陀'},
    {code:'GL',name_en:'Greenland',name_cn:'格陵兰'},
    {code:'GM',name_en:'Gambia',name_cn:'冈比亚'},
    {code:'GN',name_en:'Guinea',name_cn:'几内亚'},
    {code:'GP',name_en:'Guadeloupe',name_cn:'瓜德罗普岛'},
    {code:'GQ',name_en:'Equatorial Guinea',name_cn:'赤道几内亚'},
    {code:'GR',name_en:'Greece',name_cn:'希腊'},
    {code:'GS',name_en:'South Georgia and the South Sandwich Islands',name_cn:'南乔治亚岛和南桑威奇群岛'},
    {code:'GT',name_en:'Guatemala',name_cn:'危地马拉'},
    {code:'GU',name_en:'Guam',name_cn:'关岛'},
    {code:'GW',name_en:'Guinea-Bissau',name_cn:'几内亚比绍'},
    {code:'GY',name_en:'Guyana',name_cn:'圭亚那'},
    {code:'HK',name_en:'Hong Kong (China)',name_cn:'中国香港'},
    {code:'HM',name_en:'Heard Island and McDonald Islands',name_cn:'赫德岛和麦克唐纳群岛'},
    {code:'HN',name_en:'Honduras',name_cn:'洪都拉斯'},
    {code:'HR',name_en:'Croatia',name_cn:'克罗地亚'},
    {code:'HT',name_en:'Haiti',name_cn:'海地'},
    {code:'HU',name_en:'Hungary',name_cn:'匈牙利'},
    {code:'ID',name_en:'Indonesia',name_cn:'印度尼西亚'},
    {code:'IE',name_en:'Ireland',name_cn:'爱尔兰'},
    {code:'IL',name_en:'Israel',name_cn:'以色列'},
    {code:'IM',name_en:'Isle of Man',name_cn:'马恩岛'},
    {code:'IN',name_en:'India',name_cn:'印度'},
    {code:'IO',name_en:'British Indian Ocean Territory',name_cn:'英属印度洋领地'},
    {code:'IQ',name_en:'Iraq',name_cn:'伊拉克'},
    {code:'IR',name_en:'Iran, Islamic Republic of',name_cn:'伊朗'},
    {code:'IS',name_en:'Iceland',name_cn:'冰岛'},
    {code:'IT',name_en:'Italy',name_cn:'意大利'},
    {code:'JE',name_en:'Jersey',name_cn:'泽西岛'},
    {code:'JM',name_en:'Jamaica',name_cn:'牙买加'},
    {code:'JO',name_en:'Jordan',name_cn:'约旦'},
    {code:'JP',name_en:'Japan',name_cn:'日本'},
    {code:'KE',name_en:'Kenya',name_cn:'肯尼亚'},
    {code:'KG',name_en:'Kyrgyzstan',name_cn:'吉尔吉斯坦'},
    {code:'KH',name_en:'Cambodia',name_cn:'柬埔寨'},
    {code:'KI',name_en:'Kiribati',name_cn:'基里巴斯'},
    {code:'KM',name_en:'Comoros',name_cn:'科摩罗'},
    {code:'KN',name_en:'Saint Kitts and Nevis',name_cn:'圣基茨尼维斯'},
    {code:'KP',name_en:'North Korea',name_cn:'朝鲜'},
    {code:'KR',name_en:'South Korea',name_cn:'韩国'},
    {code:'KW',name_en:'Kuwait',name_cn:'科威特'},
    {code:'KY',name_en:'Cayman Islands',name_cn:'开曼群岛'},
    {code:'KZ',name_en:'Kazakhstan',name_cn:'哈萨克斯坦'},
    {code:'LA',name_en:'Lao People’s Democratic Republic',name_cn:'老挝'},
    {code:'LB',name_en:'Lebanon',name_cn:'黎巴嫩'},
    {code:'LC',name_en:'Saint Lucia',name_cn:'圣卢西亚岛'},
    {code:'LI',name_en:'Liechtenstein',name_cn:'列支敦士登'},
    {code:'LK',name_en:'Sri Lanka',name_cn:'斯里兰卡'},
    {code:'LR',name_en:'Liberia',name_cn:'利比里亚'},
    {code:'LS',name_en:'Lesotho',name_cn:'莱索托'},
    {code:'LT',name_en:'Lithuania',name_cn:'立陶宛'},
    {code:'LU',name_en:'Luxembourg',name_cn:'卢森堡'},
    {code:'LV',name_en:'Latvia',name_cn:'拉脱维亚'},
    {code:'LY',name_en:'Libya',name_cn:'利比亚'},
    {code:'MA',name_en:'Morocco',name_cn:'摩洛哥'},
    {code:'MC',name_en:'Monaco',name_cn:'摩纳哥'},
    {code:'MD',name_en:'Moldova, Republic of',name_cn:'摩尔多瓦'},
    {code:'ME',name_en:'Montenegro',name_cn:'黑山'},
    {code:'MF',name_en:'Saint Martin (French part)',name_cn:'圣马丁岛'},
    {code:'MG',name_en:'Madagascar',name_cn:'马达加斯加'},
    {code:'MH',name_en:'Marshall islands',name_cn:'马绍尔群岛'},
    {code:'MK',name_en:'Macedonia, the former Yugoslav Republic of',name_cn:'北马其顿'},
    {code:'ML',name_en:'Mali',name_cn:'马里'},
    {code:'MM',name_en:'Myanmar',name_cn:'缅甸'},
    {code:'MN',name_en:'Mongolia',name_cn:'蒙古'},
    {code:'MO',name_en:'Macau (China)',name_cn:'中国澳门'},
    {code:'MP',name_en:'Northern Mariana Islands',name_cn:'北马里亚纳群岛'},
    {code:'MQ',name_en:'Martinique',name_cn:'马提尼克岛'},
    {code:'MR',name_en:'Mauritania',name_cn:'毛里塔尼亚'},
    {code:'MS',name_en:'Montserrat',name_cn:'蒙特塞拉特'},
    {code:'MT',name_en:'Malta',name_cn:'马耳他'},
    {code:'MU',name_en:'Mauritius',name_cn:'毛里求斯'},
    {code:'MV',name_en:'Maldives',name_cn:'马尔代夫'},
    {code:'MW',name_en:'Malawi',name_cn:'马拉维'},
    {code:'MX',name_en:'Mexico',name_cn:'墨西哥'},
    {code:'MY',name_en:'Malaysia',name_cn:'马来西亚'},
    {code:'MZ',name_en:'Mozambique',name_cn:'莫桑比克'},
    {code:'NA',name_en:'Namibia',name_cn:'纳米比亚'},
    {code:'NC',name_en:'New Caledonia',name_cn:'新喀里多尼亚'},
    {code:'NE',name_en:'Niger',name_cn:'尼日尔'},
    {code:'NF',name_en:'Norfolk Island',name_cn:'诺福克岛'},
    {code:'NG',name_en:'Nigeria',name_cn:'尼日利亚'},
    {code:'NI',name_en:'Nicaragua',name_cn:'尼加拉瓜'},
    {code:'NL',name_en:'Netherlands',name_cn:'荷兰'},
    {code:'NO',name_en:'Norway',name_cn:'挪威'},
    {code:'NP',name_en:'Nepal',name_cn:'尼泊尔'},
    {code:'NR',name_en:'Nauru',name_cn:'瑙鲁'},
    {code:'NU',name_en:'Niue',name_cn:'纽埃'},
    {code:'NZ',name_en:'New Zealand',name_cn:'新西兰'},
    {code:'OM',name_en:'Oman',name_cn:'阿曼'},
    {code:'PA',name_en:'Panama',name_cn:'巴拿马'},
    {code:'PE',name_en:'Peru',name_cn:'秘鲁'},
    {code:'PF',name_en:'Tahiti',name_cn:'法属波利尼西亚'},
    {code:'PG',name_en:'Papua New Guinea',name_cn:'巴布亚新几内亚'},
    {code:'PH',name_en:'Philippines',name_cn:'菲律宾'},
    {code:'PK',name_en:'Pakistan',name_cn:'巴基斯坦'},
    {code:'PL',name_en:'Poland',name_cn:'波兰'},
    {code:'PM',name_en:'Saint Pierre and Miquelon',name_cn:'圣皮埃尔和密克隆'},
    {code:'PN',name_en:'Pitcairn Islands',name_cn:'皮特凯恩群岛'},
    {code:'PR',name_en:'Puerto Rico',name_cn:'波多黎各'},
    {code:'PS',name_en:'Palestine, State of',name_cn:'巴勒斯坦领土'},
    {code:'PT',name_en:'Portugal',name_cn:'葡萄牙'},
    {code:'PW',name_en:'Palau',name_cn:'帕劳群岛'},
    {code:'PY',name_en:'Paraguay',name_cn:'巴拉圭'},
    {code:'QA',name_en:'Qatar',name_cn:'卡塔尔'},
    {code:'RE',name_en:'Reunion',name_cn:'留尼汪岛'},
    {code:'RO',name_en:'Romania',name_cn:'罗马尼亚'},
    {code:'RS',name_en:'Serbia',name_cn:'塞尔维亚'},
    {code:'RU',name_en:'Russia',name_cn:'俄罗斯'},
    {code:'RW',name_en:'Rwanda',name_cn:'卢旺达'},
    {code:'SA',name_en:'Saudi Arabia',name_cn:'沙特阿拉伯'},
    {code:'SB',name_en:'Solomon Islands',name_cn:'所罗门群岛'},
    {code:'SC',name_en:'Seychelles',name_cn:'塞舌尔'},
    {code:'SD',name_en:'Sudan',name_cn:'苏丹'},
    {code:'SE',name_en:'Sweden',name_cn:'瑞典'},
    {code:'SG',name_en:'Singapore',name_cn:'新加坡'},
    {code:'SI',name_en:'Slovenia',name_cn:'斯洛文尼亚'},
    {code:'SJ',name_en:'Svalbard and Jan Mayen',name_cn:'斯瓦尔巴特群岛'},
    {code:'SK',name_en:'Slovakia',name_cn:'斯洛伐克'},
    {code:'SL',name_en:'Sierra Leone',name_cn:'塞拉利昂'},
    {code:'SM',name_en:'San Marino',name_cn:'圣马利诺'},
    {code:'SN',name_en:'Senegal',name_cn:'塞内加尔'},
    {code:'SO',name_en:'Somalia',name_cn:'索马里'},
    {code:'SR',name_en:'Suriname',name_cn:'苏里南'},
    {code:'SS',name_en:'South Sudan',name_cn:'南苏丹'},
    {code:'ST',name_en:'Sao Tome and Principe',name_cn:'圣多美和普林西比'},
    {code:'SV',name_en:'El Salvador',name_cn:'萨尔瓦多'},
    {code:'SX',name_en:'Sint Maarten (Dutch part)',name_cn:'荷属圣马丁'},
    {code:'SY',name_en:'Syrian Arab Republic',name_cn:'叙利亚'},
    {code:'SZ',name_en:'Swaziland',name_cn:'斯威士兰'},
    {code:'TC',name_en:'Turks and Caicos Islands',name_cn:'特克斯和凯科斯群岛'},
    {code:'TD',name_en:'Chad',name_cn:'查德'},
    {code:'TG',name_en:'Togo',name_cn:'多哥'},
    {code:'TH',name_en:'Thailand',name_cn:'泰国'},
    {code:'TJ',name_en:'Tajikistan',name_cn:'塔吉克斯坦'},
    {code:'TK',name_en:'Tokelau',name_cn:'托克劳群岛'},
    {code:'TL',name_en:'Timor-Leste',name_cn:'东帝汶'},
    {code:'TM',name_en:'Turkmenistan',name_cn:'土库曼斯坦'},
    {code:'TN',name_en:'Tunisia',name_cn:'突尼斯'},
    {code:'TO',name_en:'Tonga',name_cn:'汤加群岛'},
    {code:'TR',name_en:'Turkey',name_cn:'土耳其'},
    {code:'TT',name_en:'Trinidad and Tobago',name_cn:'特立尼达和多巴哥'},
    {code:'TV',name_en:'Tuvalu',name_cn:'图瓦卢'},
    {code:'TW',name_en:'Taiwan (China)',name_cn:'中国台湾'},
    {code:'TZ',name_en:'Tanzania, United Republic of',name_cn:'坦桑尼亚'},
    {code:'UA',name_en:'Ukraine',name_cn:'乌克兰'},
    {code:'UG',name_en:'Uganda',name_cn:'乌干达'},
    {code:'UM',name_en:'United States Minor Outlying Islands',name_cn:'美国本土外小岛屿'},
    {code:'US',name_en:'United States',name_cn:'美国'},
    {code:'UY',name_en:'Uruguay',name_cn:'乌拉圭'},
    {code:'UZ',name_en:'Uzbekistan',name_cn:'乌兹别克斯坦'},
    {code:'VA',name_en:'Holy See (Vatican City State)',name_cn:'梵蒂冈'},
    {code:'VC',name_en:'Saint Vincent and the Grenadines',name_cn:'圣文森'},
    {code:'VE',name_en:'Venezuela, Bolivarian Republic of',name_cn:'委内瑞拉'},
    {code:'VG',name_en:'Virgin Islands, U.S.',name_cn:'英属维尔京群岛'},
    {code:'VI',name_en:'United States Virgin Islands',name_cn:'美属维尔京群岛'},
    {code:'VN',name_en:'Vietnam',name_cn:'越南'},
    {code:'VU',name_en:'Vanuatu',name_cn:'瓦努阿图'},
    {code:'WF',name_en:'Wallis and Futuna',name_cn:'瓦利斯群岛和富图纳群岛'},
    {code:'WS',name_en:'Samoa',name_cn:'萨摩亚'},
    {code:'YE',name_en:'Yemen',name_cn:'也门'},
    {code:'YT',name_en:'Mayotte',name_cn:'马约特'},
    {code:'ZA',name_en:'South Africa',name_cn:'南非'},
    {code:'ZM',name_en:'Zambia',name_cn:'赞比亚'},
    {code:'ZW',name_en:'Zimbabwe',name_cn:'津巴布韦'},
]