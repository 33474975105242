import {
    HOTEL_SEARCH_KEYWORD,
    HOTEL_TOP_HOTELS,
    HOTEL_GET_LIST_FILTERS,
    HOTEL_SEARCH_HOTELS,
    HOTEL_DETAIL,
    HOTEL_FAVORITE,
    HOTEL_FAVORITE_CANCEL,
    HOTEL_SEARCH_ROOMS, HOTEL_CONFIRM_RATE
} from '@/services/api'
import {request, METHOD} from '@/utils/request'

export async function getTopHotels() {
    return request(HOTEL_TOP_HOTELS, METHOD.GET)
}

export async function searchKeywords(keyword) {
    return request(HOTEL_SEARCH_KEYWORD, METHOD.GET, {
        keyword: keyword.trim()
    })
}

export async function getHotelListFilters(cityCode) {
    return request(HOTEL_GET_LIST_FILTERS, METHOD.GET, {
        CityCode: cityCode
    })
}

export async function searchHotels(params) {
    return request(HOTEL_SEARCH_HOTELS, METHOD.POST, params)
}

export async function getHotelDetail(hotelId, language) {
    return request(HOTEL_DETAIL, METHOD.GET, {HotelId: hotelId, language: language})
}

export async function saveFavorite(hotelId) {
    return request(HOTEL_FAVORITE, METHOD.GET, {HotelId: hotelId})
}

export async function cancelFavorite(hotelId) {
    return request(HOTEL_FAVORITE_CANCEL, METHOD.GET, {HotelId: hotelId})
}

export async function searchRoomRates(params) {
    return request(HOTEL_SEARCH_ROOMS, METHOD.POST, params)
}

export async function confirmRate(params) {
    return request(HOTEL_CONFIRM_RATE, METHOD.POST, params)
}